<template>
  <div>
    <tim-kiem
      :is-tim-kiem-chi-so-sidebar-active.sync="isTimKiemChiSoSidebarActive"
      @refetch-data="refetchData"
    />
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title>Ý nghĩa các con số</b-card-title>
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          dropright
          text="Chức năng"
          :right="$store.state.appConfig.isRTL"
        >
          <b-dropdown-item :to="{ name: 'them-y-nghia-cac-con-so' }">
            <feather-icon
              icon="UserPlusIcon"
              class="mr-50"
            />
            <span class="align-middle ml-50">Thêm</span>
          </b-dropdown-item>
          <b-dropdown-item @click="isTimKiemChiSoSidebarActive = true">
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle ml-50">Tìm kiếm</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>

      <b-table
        ref="refChiSoTable"
        class="position-relative"
        empty-text="Không có dữ liệu"
        show-empty
        responsive="sm"
        stacked="md"
        :items="fetchUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        primary-key="id"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- A virtual column -->
        <template #cell(id)="data">
          <p class="text-right">
            {{ data.index+1 }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            dropright
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'xem-chi-tiet-y-nghia-con-so', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Xem chi tiết</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{name: 'sua-y-nghia-cac-con-so', params: {id: data.item.id}}">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Sửa</span>
            </b-dropdown-item>

            <b-dropdown-item @click="xoaKhach(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Xóa</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Dòng {{ dataMeta.from }} đến {{ dataMeta.to }} / {{ dataMeta.of }} bản ghi</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BDropdown, BDropdownItem,
  BCardTitle,
  BCardHeader,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import TimKiem from '@/views/y-nghia-cac-con-so/list/TimKiemChiSo.vue'
import useYNghiaCacConSoList from './useYNghiaCacConSoList'
import yNghiaCacConSoStoreModule from '../yNghiaCacConSoStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    TimKiem,
    BCardTitle,
    BCardHeader,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-y-nghia-cac-con-so'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, yNghiaCacConSoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const isTimKiemChiSoSidebarActive = ref(false)

    const {
      fieldsTimKiem,
      thucHienTimKiem,
      fetchUsers,
      xoaKhachHang,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChiSoTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useYNghiaCacConSoList()

    return {
      isTimKiemChiSoSidebarActive,
      fieldsTimKiem,
      thucHienTimKiem,
      // Sidebar
      fetchUsers,
      xoaKhachHang,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChiSoTable,
      refetchData,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  mounted() {
    this.$root.$on('timKiemChiSo', data => {
      this.isTimKiemChiSoSidebarActive = false
      this.fieldsTimKiem = data
      this.thucHienTimKiem = true
      this.refetchData()
    })
  },
  methods: {
    xoaKhach(id) {
      this.$swal({
        title: 'Bạn có chắc chắn thực hiện việc này?',
        text: 'Dữ liệu sau khi xóa không thể phục hồi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.xoaKhachHang(id, () => {
            this.$refs.refChiSoTable.refresh()
          }, () => {
            this.$store.state.showBlock = true
          }, () => {
            this.$store.state.showBlock = false
          }, (message, type) => {
            this.showToast(type, 'Thông báo', 'BellIcon', message)
          })
        }
      }).catch(e => {
        this.showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
      })
    },
    suaKhach(id) {
      this.isAddNewUserSidebarActive = true
      this.$root.$emit('updateKhach', id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
