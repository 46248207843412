<template>
  <b-sidebar
    id="search-chi-so-form"
    :visible="isTimKiemChiSoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-tim-kiem-chi-so-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Tìm kiếm chỉ số
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >

        <!-- Nhóm -->
        <b-form-group
          label="Nhóm"
          label-for="nhom-y-nghia-search"
        >
          <v-select
            v-model="userData.nhom"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="nhom"
            :clearable="true"
            input-id="nhom-y-nghia-search"
          />
        </b-form-group>
        <!-- Chỉ số -->
        <b-form-group
          label="Chỉ số"
          label-for="search_chi_so"
        >
          <b-form-input
            id="search_chi_so"
            v-model="userData.chi_so"
            trim
            placeholder="Nhập số"
          />
        </b-form-group>

        <!-- Chủ đề -->
        <b-form-group
          label="Chủ đề"
          label-for="search_chu_de"
        >
          <b-form-input
            id="search_chu_de"
            v-model="userData.chu_de"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Nội dung -->
        <b-form-group
          label="Nội dung"
          label-for="search_noi_dung"
        >
          <b-form-input
            id="search_noi_dung"
            v-model="userData.noi_dung"
            autofocus
            trim
          />

        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-2"
            type="submit"
          >
            <feather-icon
              icon="SearchIcon"
            />
            Tìm kiếm
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Hủy
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // Form Validation
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTimKiemChiSoSidebarActive',
    event: 'update:is-tim-kiem-chi-so-sidebar-active',
  },
  props: {
    isTimKiemChiSoSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      nhom: [
        { label: 'Đường đời', value: 'Đường đời' },
        { label: 'Chỉ số đường đời KID', value: 'Đường đời trẻ em' },
        { label: 'Đường đời cha mẹ', value: 'Đường đời cha mẹ' },
        { label: 'Chỉ số ngày sinh', value: 'Chỉ số ngày sinh' },
        { label: 'Chỉ số ngày sinh KID', value: 'Ngày sinh trẻ em' },
        { label: 'Chỉ số tâm hồn', value: 'Chỉ số tâm hồn' },
        { label: 'Chỉ số tâm hồn KID', value: 'Tâm hồn trẻ em' },
        { label: 'Số trưởng thành', value: 'Số trưởng thành' },
        { label: 'Số trưởng thành KID', value: 'Trưởng thành trẻ em' },
        { label: 'Số thử thách', value: 'Số thách thức' },
        { label: 'Số thử thách KID', value: 'Thử thách trẻ em' },
        { label: 'Tháng thần số', value: 'Tháng thần số' },
        { label: 'Năm thần số', value: 'Năm thần số' },
        { label: 'Chỉ số vận mệnh', value: 'Chỉ số vận mệnh' },
        { label: 'Chỉ số vận mệnh KID', value: 'Vận mệnh trẻ em' },
        { label: 'Chỉ số vòng đời', value: 'Chỉ số vòng đời' },
        { label: 'Số lý trí', value: 'Số lý trí' },
        { label: 'Đỉnh trưởng thành', value: 'Đỉnh trưởng thành' },
        { label: 'Số bản thể', value: 'Số bản thể' },
        { label: 'Chỉ số hướng nghiệp', value: 'Chỉ số hướng nghiệp' },
        { label: 'Năm thần số trước - sau', value: 'Năm thần số trước - sau' },
        { label: 'Số thử thách thói quen', value: 'Số thử thách thói quen' },
      ],
    }
  },
  setup() {
    const blankUserData = {
      nhom: '',
      chi_so: '',
      noi_dung: '',
      chu_de: '',
    }

    const listVaiTro = ref([])

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const setUserData = user => {
      userData.value = user
    }

    const {
      resetForm,
    } = formValidation(resetuserData)

    return {
      listVaiTro,
      userData,
      resetForm,
      setUserData,
    }
  },
  methods: {
    submit() {
      const currentUser = getUserData()

      Object.assign(this.userData, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        timKiem: 1,
      })
      this.$root.$emit('timKiemChiSo', this.userData)
    },
    onSubmit() {
      this.submit()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
